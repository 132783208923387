<template>
    <v-container grid-list-md>
        <v-layout row wrap justify-center>
            <v-flex xs9>
                <v-card color="transparent" flat>
                    <v-card-title
                            class="display-1 text-xs-center font-weight-medium teal--text mb-4">
                        <v-spacer></v-spacer>
<!--                        Tarife Hausrat-->
                        {{$t('hausrat.header__main')}}
                        <v-spacer></v-spacer>
                    </v-card-title>

                    <v-layout row wrap justify-center>
                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" flat hover
                                    to="/hausrat/standard/2">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
<!--                                        Hausrat Basis-->
                                        {{$t('hausrat.tariff_basis.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>
                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 2,90 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
<!--                                        pro Monat-->
                                        {{$t('hausrat.tariff_basis.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
<!--                                        Ersetzt im Schadensfall alles,<br>-->
<!--                                        was Ihnen lieb ist zum Neupreis <br>-->
<!--                                        Top Tarif passend zu jeder <br>-->
<!--                                        Lebenssituation-->
                                        {{$t('hausrat.tariff_basis.text-1')}}<br>
                                        {{$t('hausrat.tariff_basis.text-2')}}<br>
                                        {{$t('hausrat.tariff_basis.text-3')}}<br>
                                        {{$t('hausrat.tariff_basis.text-4')}}
                                    </p>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>

                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" flat hover
                                    to="/hausrat/standard/1">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
<!--                                        Hausrat Kombi-->
                                        {{$t('hausrat.tariff_combo.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>

                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 6,50 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
<!--                                        pro Monat-->
                                        {{$t('hausrat.tariff_combo.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
<!--                                        Deckungssumme bis <br>-->
<!--                                        6 Millionen Euro <br>-->
<!--                                        Ideal für Singles <br>-->
<!--                                        mit Wohnraum bis 75 m² <br>-->
<!--                                        Im Kombipaket günstiger-->
                                        {{$t('hausrat.tariff_combo.text-1')}}<br>
                                        {{$t('hausrat.tariff_combo.text-2')}}<br>
                                        {{$t('hausrat.tariff_combo.text-3')}}<br>
                                        {{$t('hausrat.tariff_combo.text-4')}}<br>
                                        {{$t('hausrat.tariff_combo.text-5')}}
                                    </p>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'Hausrat',
    /**
     * Object that represent the meta information of site Home page. On page
     * load, above meta tag is added to the page.
     * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="Hausrat">
     */
    metaInfo: {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Hausrat',
        },
      ],
    },

    created() {
      // Moves to top of the page when load.
      window.scrollTo(0, 0);
    },
    mounted() {
    },
  };
</script>

<style scoped>

</style>