import Vue from "vue"
import Vuex from "vuex"
import db from "../storage"
import {
    floodlight,
    internalTrack,
    loadCampaign,
    loadIdent,
    loadInfo, updateNotifications
} from "@/assets/insure";
import i18n from "@/i18n";
import router from "@/router";
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            id: null,
            name: "",
            email: "",
            logged_in: false,
            url: "",
            fcData: {
                notificationStatus: {
                    "LI-OM": false,
                    "LI-OPT": false,
                    "LI-NBA": false,
                },
                permissions: {
                    "ChannelEmail": true,
                    "ChannelMessenger": true,
                    "ChannelPhone": true,
                    "ChannelPost": true,
                    "ChannelSms": true,
                    "DataGeo": true,
                    "DataPd": true,
                    "DataWbt": true,
                    "LI-NBA": true,
                    "LI-OM": true,
                    "LI-OPT": true,
                },
                state: null,
                umdid: null,
                umid: null,
            },
        },
        // Notification model
        notification: {
            type: "info",
            text: "",
            show: false,
        },
        // Notification type
        notify: {
            visitor: false,
            customer: false,
        },
        // Object representing an alert into the login form.
        alert: {
            display: false,
            type: "error",
            color: "",
            text: "",
        },
        // Loading pin processing status.
        processing: false,
        // Contains the different popups used in web.
        dialogs: {
            // Visualization status of register popup.
            register: false,
            // Visualization status for login popup.
            login: false,
            // Visualization status for password recover popup.
            password_recover: false,
            // Visualization status for password change popup.
            password_change: false,
            // Visualization status for cookies(opt-in version) information popup.
            cookies_opt_in: false,
            // Visualization status for cookies(opt-in version) permissions popup.
            cookies_permissions: false,
        },
        // Object containing default status of different formularies used.
        forms: {
            register: false,
            login: false,
            password_recover: false,
            password_change: false,
        },
        // Represent when user has accessed to confirmation page after registering.
        confirmation: false,
    },
    mutations: {
        SET_USER_STATE(state, payload) {
            typeof payload.value === "object" ?
                state.user = payload.value :
                state.user[payload.name] = payload.value
        },
        SET_USER_FCDATA(state, payload) {
            typeof payload.value === "object" ?
                state.user.fcData = payload.value :
                state.user.fcData[payload.name] = payload.value
        },
        SET_COOKIE_DIALOG(state, payload) {
            state.notify[payload.name] = payload.value;
        },
        SET_DIALOG(state, payload) {
            if (payload.name.indexOf("password_recover") >= -1) state.dialogs.login = false
            state.dialogs[payload.name] = payload.value
        },
        SET_NOTIFICATION(state, payload) {
            state.notification.type = payload.type;
            state.notification.text = payload.text || state.notification.text
            state.notification.show = payload.show || state.notification.show
        },
        SET_PROCESSING_STATUS(state, value) {
            state.processing = value;
        },
        SET_PERMISSION(state, payload) {
            let name = payload.name;
            state.user.fcData.permissions[name] = payload.value;
        },
        SET_FORM_TO_DEFAULT(state) {
            state.forms.register = false
            state.forms.login = false
            state.forms.password_change = false
            state.forms.password_recover = false
        },
        CLEAR_FORM(state, payload) {
            state.forms[payload.name] = payload.value
        },
    },
    actions: {
        /**
         * Looks into database for logged-in user.
         */
        start({state, commit, dispatch}) {
            console.log("Log: Looking for logged user.");
            db.findOne({"insure.user.logged_in": true}, (err, docs) => {
                if (docs !== null) { /* User found */
                    console.log("Log: User found", docs.insure.user.email);
                    commit("SET_USER_STATE", {
                        name: "user",
                        value: docs.insure.user
                    });
                    if (state.confirmation) dispatch("logOut");
                    commit("SET_USER_STATE", {name: "logged_in", value: true});
                    window.localStorage.setItem("userEmail", state.user.email)
                    // call ident function.
                    loadIdent(state.user.email).then(() => dispatch("handleInfoCall"));
                } else {
                    // call secondary calls.
                    console.info("No user found, continue");
                    dispatch("handleInfoCall");
                }
            });
        },
        /**
         * Load info call and set the response data.
         * @param state
         * @param commit
         * @param dispatch
         */
        handleInfoCall({state, commit, dispatch}) {
            loadInfo().then(data => {
                commit("SET_USER_FCDATA", {value: data})
                commit("SET_DIALOG", {
                    name: "cookies_opt_in",
                    value: (state.user.fcData.notificationStatus["LI-OM"] &&
                        state.user.fcData.notificationStatus["LI-OPT"]) === false
                })
                if (data.state === 1) {
                    commit("SET_COOKIE_DIALOG", {
                        name: "customer",
                        value: state.user.fcData.notificationStatus["LI-NBA"] === false
                    })
                }
                if (state.user.fcData.permissions["LI-OM"]) {
                    internalTrack()
                    floodlight()
                }
                dispatch("loadBestActionBanner")
            })
        },
        /**
         * Logs an user into the website.
         * @param state
         * @param commit
         * @param dispatch
         * @param payload Comes with results from call to backend.
         */
        authenticateUser({state, commit, dispatch}, payload) {
            commit("SET_PROCESSING_STATUS", true);
            // Set user object with data from backend.
            commit("SET_USER_STATE", {
                user: {
                    id: payload.id,
                    email: payload.email,
                    logged_in: true,
                },
            });
            // Look for user on the database
            db.findOne({"insure.user.email": payload.email}, (err, row) => {
                if (err) {
                    console.log("Err:", err);
                }
                // In case we found no User on IMD, we insert one.
                if (!row) {
                    console.log("Log: No user found.", row);
                    db.insert({
                        insure: {
                            user: {
                                id: payload.id,
                                email: payload.email,
                                logged_in: true,
                            },
                        },
                    }, (err) => {
                        if (err) console.log("Err:", err);
                    });
                } else {
                    // If User is found on IMD.
                    console.log("Log: User found", row.insure.user.email);
                    db.update({"insure.user.email": payload.email},
                        {$set: {"insure.user.logged_in": true}}, {}, (err) => {
                            if (err) console.log("Err:", err);
                        });
                }
            });
            window.localStorage.setItem("userEmail", state.user.email)
            loadIdent(payload.email).then(() => {
                dispatch("handleInfoCall");
            });
            commit("SET_USER_STATE", {
                value: {
                    id: payload.id,
                    email: payload.email,
                    logged_in: true,
                    url: payload.url
                }
            });
            commit("SET_PROCESSING_STATUS", false);
            commit("SET_DIALOG", {name: "login", value: false});
            commit("SET_NOTIFICATION", {
                type: "success",
                text: i18n.t("common.dialog__login-successful",
                    {email: state.user.email}),
                show: true,
            });
            // router.go()
        },
        /**
         * Log out an user from the website.
         * @param state
         * @param commit
         */
        logOut({state, commit}) {
            axios.get(`${axios.defaults.baseURL}/user/out?id=${state.user.id}`)
                .then(() => {
                    db.findOne({'insure.user.email': state.user.email},
                        (err, docs) => {
                            if (!err) {
                                if (docs && docs.insure.user.email === state.user.email) {
                                    db.update({'insure.user.email': state.user.email},
                                        {$set: {'insure.user.logged_in': false}}, {},
                                        (err, numRows) => {
                                            if (numRows > 0) {
                                                console.log('User logged out.');
                                                commit('SET_USER_STATE', {
                                                    value: {
                                                        id: null,
                                                        name: '',
                                                        email: '',
                                                        logged_in: false,
                                                        fcData: {
                                                            state: 0,
                                                            umid: null,
                                                            umdid: null,
                                                            notificationStatus: {
                                                                'LI-OM': false,
                                                                'LI-OPT': false,
                                                                'LI-NBA': false,
                                                            },
                                                            permissions: {
                                                                'LI-OM': true,
                                                                'LI-NBA': true,
                                                                'LI-OPT': true,
                                                                'DATA-WBT': true,
                                                                'DATA-GEO': true,
                                                                'DATA-PD': true,
                                                                'CHANNEL-MAIL': true,
                                                                'CHANNEL-PHONE': true,
                                                                'CHANNEL-POST': true,
                                                                'CHANNEL-SMS': true,
                                                                'CHANNEL-MESSENGER': true,
                                                            },
                                                        },
                                                    },
                                                });
                                                commit('SET_USER_STATE', {
                                                    name: "logged_in",
                                                    value: false
                                                });
                                                router.go();
                                            }
                                        });
                                }
                            }
                        });
                }).catch(err => console.log("Error", err.response))
            window.localStorage.removeItem("userEmail")
        },
        /**
         * Removes user account from backend database.
         * @param state
         * @param commit
         */
        removeUserAccount({state, commit}) {
            // First send request to delete user in backend.
            axios.post(`${axios.defaults.baseURL}/user/delete`,
                JSON.stringify({
                    email: state.user.email,
                    url: axios.defaults.baseURL,
                })).then(() => {
                // First we log current user off.
                db.findOne({"insure.user.email": state.user.email},
                    (err, docs) => {
                        if (!err) {
                            if (docs && docs.insure.user.email === state.user.email) {
                                // then set logged in variable to false
                                db.update({"insure.user.email": docs.insure.user.email},
                                    {$set: {"insure.user.logged_in": false}}, {},
                                    (err, numRows) => {
                                        if (numRows > 0) {
                                            commit("SET_NOTIFICATION", {
                                                type: "success",
                                                text: "Your account has been removed",
                                                show: true,
                                            });
                                        }
                                    });
                            }
                        }
                    })
            })
                // .finally(() => router.go())
        },
        /**
         * Load Celtra campaign banner on top.
         * @param state
         * @param commit
         */
        loadBestActionBanner({state}) {
            let banner_code = loadCampaign("f4e29141", i18n.locale,
                state.user.fcData.permissions["LI-OM"]);
            let banner = document.getElementById("ads_banner");
            if (banner && banner.firstChild !== null) {
                banner.firstChild.remove();
            }
            banner.insertAdjacentElement("afterbegin", banner_code);
        },
        /**
         * Sets the status of a dialog.
         * @param commit
         * @param payload
         */
        setDialogStatus({commit}, payload) {
            commit("SET_DIALOG", payload);
        },
        /**
         * Set the status for data processing.
         * @param commit
         * @param value
         */
        setProcessingStatus({commit}, value) {
            commit("SET_PROCESSING_STATUS", value)
        },
        /**
         * Set the permissions LI-OM and LI-OPT to true.
         * @param dispatch
         * @param commit
         */
        acceptVisitorCookies({dispatch, commit}) {
            commit('SET_PROCESSING_STATUS', true);
            updateNotifications({
                name: 'MAIN_P',
                permissions: ['LI-OM', 'LI-OPT'],
                version: 2,
            }).then(() => {
                commit('SET_PROCESSING_STATUS', false);
                commit('SET_DIALOG', {
                    name: 'cookies_opt_in',
                    value: false,
                });
                dispatch('handleInfoCall');
            });
        },
        /**
         * Set permission LI-NBA to true
         *
         * @param dispatch
         * @param commit
         */
        acceptCustomerCookies({dispatch, commit}) {
            commit('SET_PROCESSING_STATUS', true);
            updateNotifications({
                name: 'MAIN_CD',
                permissions: ['LI-OM', 'LI-OPT', 'LI-NBA'],
                version: 2,
            }).then(() => {
                commit('SET_PROCESSING_STATUS', false);
                commit('SET_COOKIES_DIALOG', {
                    name: 'customer',
                    value: false,
                });
                dispatch('handleInfoCall');
            });
        },
        // Toggle cookies dialog
        toggleCookiesDialog({commit}, payload){
            commit("SET_COOKIE_DIALOG", payload)
        },
        /**
         * Dismisses a notification pop up.
         * @param commit
         */
        toggleNotification({commit}) {
            commit("SET_NOTIFICATION", {show: false})
        },
        toggleForm({commit}, payload) {
            commit("SET_DIALOG", payload)
        }
    }
})
