<template>
  <v-container grid-list-md fill-height>
    <v-layout row wrap justify-center align-center>
      <v-flex xs3 v-if="loading">
        <v-progress-circular color="teal" size="20" width="1"
                             indeterminate></v-progress-circular>
        <span class="subheading font-weight-light ml-2">
                    {{ $t('common.processing__account-activation') }}...
                </span>
      </v-flex>

      <v-flex xs9 v-else>
        <v-alert :type="color" :value="true">
          {{
            $t('common.alert__account-activation-' +
                information_text)
          }}
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {getConsoleInfoFunc} from "@/helpers";

export default {
  name: 'EmailConfirmation',
  /**
   * Object that represent the meta information of site Home page. On page
   * load, above meta tag is added to the page.
   * <meta data-vue-meta="1" vmid="og:title" property="og:title"
   * content="EmailConfirmation">
   */
  metaInfo: {
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'EmailConfirmation',
      },
    ],
  },
  data: () => ({
    loading: false,
    valid_email: '',
    color: 'info',
    success_text: '',
    error_text: '',
    information_text: '',
  }),
  methods: {
    /**
     * Call to API to validate if email exists. Emits a command globally
     * to show information whether email is found or not.
     * @param email
     */
    validateEmail(email) {
      this.loading = true;
      this.axios.post(`${this.axios.defaults.baseURL}/user`,
          JSON.stringify({
            email: email,
            url: this.axios.defaults.baseURL
          })).then(() => {
        this.loading = false;
        this.color = 'success';
        this.information_text = 'successful';
        this.$root.$emit('email_confirmed');
      }).catch(err => {
        this.loading = false;
        this.color = 'error';
        this.information_text = 'error';
        this.$root.$emit('email_unknown');
        getConsoleInfoFunc("error", err.response)
      });
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    // Generates a valid email from route parameter.
    this.loading = true; /* For slow connections use a loading pin */
    this.validateEmail(this.$route.params.email);
  },
};
</script>

<style scoped>

</style>
