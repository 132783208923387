/* eslint-disable */

import axios from "axios";
import {getConsoleInfoFunc} from "@/helpers";

window.FC_CONF = {
    META_TAGS: ["og:title", "twitter:site"],
    SESSION_STORAGE: "fcData",
    FLOODLIGHT_ID: "eShopeFloodlight",
    API_HOST: "https://fc.teavarodemo.biz/op",
    API_INFO_URL: "/eshop-web-info/info",
    API_IDENT_URL: "/eshop-web-ident/ident",
    API_TRACK_URL: "/eshop-web-track/track",
    API_FLOODLIGHT_URL: "/nectar-web-fls/track",
    IDCONNECT_API_HOST: "https://fc.teavarodemo.biz/op/idconnect",
    IDCONNECT_MANUAL_ROUTING: false,
    IDCONNECT_CONNECT_TO_FC: true,
    IDCONNECT_NEXT_BEST_DOMAIN: "",
    IDCONNECT_SESSION_STORAGE: "fcIdConnectData",
};

export const loadInfo = () => {
    return new Promise((resolve) => {
        // Info Call Multiple parameters
        FC.info(["attributes", "notifications", "permissions"]).then(({data}) => {
            getConsoleInfoFunc("info", "Info Call with multiple parameters");
            resolve(data);
        });
    });
};

export const loadIdent = (email) => {
    return new Promise((resolve, reject) => {
        axios.post("https://hash.teavaro.net/hash", [{Salt: "", Value: email}],
            {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-api-key": "LvuPYvH9M26pJY0F63YJD5YlKUutHsDz8iIY99hb",
                },
            }).then(response => {
            FC.ident({fc_hem: response.data[0].Hex}).then(({data}) => {
                getConsoleInfoFunc("info", "Ident call with hashed email");
                resolve(data);
            });
        }).catch(error => reject(error));
    });
};

// eslint-disable-next-line no-unused-vars
export const updateNotifications = (permissions) => {
    return new Promise(resolve => {
        // Notification Call with notifications response
        FC.notification([permissions], ["notifications"]).then(({data}) => {
            getConsoleInfoFunc("info", "Notification Call with notification response");
            resolve(data);
        });
    });
};

// eslint-disable-next-line no-unused-vars
export const updatePermissions = (permission) => {
    return new Promise(resolve => {
        // Permission Call with permissions response
        FC.permission(permission, ["permissions"]).then(({data}) => {
            getConsoleInfoFunc("info", "Permission Call with permission response");
            resolve(data);
        });
    });
};

export const internalTrack = () => {
    return new Promise(resolve => {
        // Internal Track Call with show pixel
        FC.track(true).then(({data}) => {
            getConsoleInfoFunc("info", "Internal Track Call with s2s=2");
            resolve(data);
        });
    });
};

export const floodlight = () => {
    FC.floodlight().then(({data}) => {
        getConsoleInfoFunc("info", "Floodlight Call");
    });
};

/**
 * Call Celtra service with a placement Id to display Next Best Action Banner.
 *
 * @param placementId string
 * @param language string
 * @param track Boolean
 */
export const loadCampaign = (placementId, language, track) => {
    let imageTag = (img) => {
        let params = {
            "androidAdvId": "%VAR_androidaid%",
            "clickEvent": "advertiser",
            "clickUrl": "%LINK%?redir=",
            "clickUrlNeedsDest": "1",
            "externalAdServer": "MOcean",
            "iosAdvId": "%VAR_idfa%",
            "placementId": placementId,
            "preferredClickThroughWindow": "",
            "siteLanguage": language,
            "allowTracking": track,
        };
        let req = document.createElement("script");
        req.id = params.scriptId = `celtra-script-${(window.celtraScriptIndex = (window.celtraScriptIndex ||
            0) + 1)}`;
        params.clientTimestamp = Date.now() / 1000;

        let src = "https://fc.teavarodemo.biz/op/insurance1/ad?";
        for (let k in params) {
            src += `&${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
        }
        req.src = src;
        img.parentNode.insertBefore(req, img.nextSibling);
    };

    let campaignTag = document.createElement("div");
    campaignTag.setAttribute("class", "celtra-ad-v3");
    let image = document.createElement("img");
    image.setAttribute("src", "data:image,celtra");
    image.setAttribute("style", "display: none;");
    image.onerror = (event) => imageTag(event.target);
    campaignTag.appendChild(image);

    return campaignTag;
};
