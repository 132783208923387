<template>
  <v-container grid-list-md fill-height>
    <v-layout row wrap justify-center align-center>
      <v-flex xs12 sm11 md9>
        <v-alert v-model="alert.display"
                 :type="alert.type"
                 dismissible>
          {{ $t('common.reset__password-' + alert.text) }}
        </v-alert>
      </v-flex>
      <v-flex xs12 sm9 md6 v-if="match">
        <h2 class="title teal--text text-xs-center">
          {{ $t('password_reset.header') }}</h2>
        <v-form @submit.prevent="resetPassword" ref="form">
          <v-text-field v-model="password"
                        :label="$t('common.field__password-new-header')"
                        prepend-inner-icon="keyboard"
                        :append-icon="visible ? 'visibility_off' : 'visibility'"
                        @click:append="visible=!visible"
                        :type="visible ? 'text' : 'password'"
                        :rules="[v =>!!v|| $t('common.field__text-validation-error'),
                                           v => v && v.length>=6 || $t('common.field__password-validation-error')]"
                        clearable
          ></v-text-field>

          <v-text-field v-model="password_confirm"
                        :label="$t('common.field__password-confirm-header')"
                        @keyup.enter="resetPassword"
                        prepend-inner-icon="keyboard"
                        :append-icon="visible ? 'visibility_off' : 'visibility'"
                        @click:append="visible=!visible"
                        :type="visible ? 'text' : 'password'"
                        :rules="[v=>!!v|| $t('common.field__text-validation-error'),
                                      v => v && v.length>=6 || $t('common.field__password-validation-error'),
                                      v => v === password || $t('common.field__password-invalid-error')]"
                        clearable
          ></v-text-field>

          <v-btn @click="resetPassword"
                 :disabled="passwordMismatch"
                 :loading="processing"
                 class="font-weight-light text-none"
                 color="teal"
                 block dark text> {{ $t('common.button__reset') }}
          </v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'PasswordReset',
  computed: {
    passwordMismatch() {
      return !this.password || !this.password_confirm ||
          !(this.password === this.password_confirm) ||
          !(this.password.length >= 6);
    },
  },
  data: () => ({
    email: '',
    // Object representing an alert into the recovery form.
    alert: {
      display: false,
      type: 'error',
      text: '',
    },
    password: '',
    password_confirm: '',
    visible: false,
    processing: false,
    match: false,
  }),
  methods: {
    /**
     * Send data for reset the user password.
     */
    resetPassword() {
      this.processing = true;
      // eslint-disable-next-line no-undef
      axios.post(
          `${process.env.VUE_APP_API_URL}/user/password/reset?code=${this.$route.query.code}`,
          JSON.stringify({email: this.email, password: this.password}))
          .then(() => {
            this.processing = false;
            this.alert.type = 'success';
            this.alert.text = 'success';
            this.alert.display = true;
          })
          .catch(() => {
            this.processing = false;
            this.alert.type = 'error';
            this.alert.text = 'error';
            this.alert.display = true;
          })
          .finally(() => this.$refs.form.reset());
    },
  },
  mounted() {
    this.match = this.$route.query.email && this.$route.query.code;
    if (!this.match) this.$router.push('/content_not_found');
    this.email = this.$route.query.email;
  },
};
</script>

<style scoped>

</style>
