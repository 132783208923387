<template>
    <v-container grid-list-md>
        <!--Notification popup component-->
        <v-snackbar :timeout="notification.timeout"
                    :color="notification.color"
                    v-model="notification.show"
                    top
                    center>
            {{ notification.text }}
            <v-btn icon dark @click="notification.show=false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>

        <v-layout row wrap justify-center>
            <v-flex xs9>
                <v-layout row wrap>
                    <v-flex xs12>
                        <p class="headline teal--text font-weight-regular ">
                            {{ $t('datenschutz.header__main') }}
                            <!--                            Datenschutz und Privatsphäre bei InsureMe-->
                        </p>
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light wider-line-height">
                        {{ $t('datenschutz.header__main-text-one') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light wider-line-height">
                        {{ $t('datenschutz.header__main-text-two') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light">
                        {{ $t('datenschutz.header__main-text-line-1') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light">
                        {{ $t('datenschutz.header__main-text-line-2') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light">
                        {{ $t('datenschutz.header__main-text-line-3') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light">
                        {{ $t('datenschutz.header__main-text-line-4') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light">
                        {{ $t('datenschutz.header__main-text-line-5') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light wider-line-height">
                        {{ $t('datenschutz.header__main-text-three') }}
                    </v-flex>

                    <v-flex xs12
                            class="caption font-weight-light wider-line-height">
                        {{ $t('datenschutz.header__main-text-four') }}
                    </v-flex>

                    <v-flex xs12><br>
                        <v-layout row wrap>
                            <v-flex xs12 md3 lg2>
                                <v-img :src="require('@/assets/basic_cookies.png')"
                                       contain></v-img>
                            </v-flex>
                            <v-flex xs12 md9 lg10>
                                <h2 class="headline teal--text">Basic
                                    Cookies</h2>
                                <p class="font-weight-light caption wider-line-height">
                                    <!--                                    Sie besuchen unsere Website und erwarten,-->
                                    <!--                                    dass alles ordnungsgemäß funktioniert?-->
                                    <!--                                    Natürlich. Deshalb verwenden wir Cookies.-->
                                    <!--                                    Dies sind kleine Textdateien, die wir auf-->
                                    <!--                                    Ihrem Computer oder Smartphone speichern.-->
                                    <!--                                    Sie helfen Ihnen dabei, bequem auf der-->
                                    <!--                                    Seite zu navigieren. Zum Beispiel behalten-->
                                    <!--                                    wir den Inhalt Ihres Einkaufswagens oder-->
                                    <!--                                    Ihrer aktuellen Bestellung.-->
                                    {{
                                    $t('datenschutz.header__cookies-description.OM')
                                    }}
                                </p>
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                            <v-flex xs12 md3 lg2>
                                <v-img :src="require('@/assets/online_marketing_cookies.jpg')"
                                       contain></v-img>
                            </v-flex>
                            <v-flex xs12 md9 lg10>
                                <h2 class="headline teal--text">Online Marketing
                                    Cookies</h2>
                                <p class="font-weight-light caption wider-line-height">
                                    <!--                                    Sie sehen Werbung auf unserer oder einer-->
                                    <!--                                    anderen Website, die Ihren Surfgewohnheiten-->
                                    <!--                                    entspricht? Der Grund kann ein-->
                                    <!--                                    Online-Marketing-Cookie sein, den wir oder-->
                                    <!--                                    unsere-->
                                    <!--                                    Werbepartner auf Ihrem Computer oder-->
                                    <!--                                    Smartphone gespeichert haben. Wir geben-->
                                    <!--                                    grundsätzlich nur anonyme oder-->
                                    <!--                                    pseudonymisierte Daten an unsere-->
                                    <!--                                    Werbepartner weiter.-->
                                    {{
                                    $t('datenschutz.header__cookies-description.OPT')
                                    }}
                                </p>
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                            <v-flex xs12 md3 lg2>
                                <v-img :src="require('@/assets/performance_cookies.jpg')"
                                       contain></v-img>
                            </v-flex>
                            <v-flex xs12 md9 lg10>
                                <h2 class="headline teal--text">Performance
                                    Cookies</h2>
                                <p class="font-weight-light caption wider-line-height">
                                    <!--                                    Mit Performance-Cookies messen wir, ob wir-->
                                    <!--                                    auf unserer Website alles richtig machen.-->
                                    <!--                                    Und bewerten wo wir uns noch verbessern-->
                                    <!--                                    können. Zum Beispiel zählen wir, wie viele-->
                                    <!--                                    Besucher wir auf einer bestimmten Seite-->
                                    <!--                                    haben. Hierzu verwenden wir ausschließlich-->
                                    <!--                                    anonyme Daten. Ein Rückschluss auf Sie als-->
                                    <!--                                    konkrete Person ist nicht möglich.-->
                                    {{$t('datenschutz.header__cookies-description.NBA')}}
                                </p>
                            </v-flex>
                        </v-layout>
                        <br>
                    </v-flex>

                    <v-flex xs12>
                        <h2 class="headline teal--text">
                            <!--                            Verwalten Sie Ihre InsureMe Cookies selbst-->
                            {{ $t('datenschutz.header__subtitle') }}
                        </h2>
                        <p class="font-weight-light caption wider-line-height">
                            <!--                            Sie möchten keine Cookies? Einige Dinge werden beim-->
                            <!--                            Surfen nicht richtig funktionieren.-->
                            <!--                            Willst du es trotzdem nicht? Gehen Sie direkt in die-->
                            <!--                            Einstellungen Ihres Browsers. Zum-->
                            <!--                            Beispiel in Internet Explorer, Safari oder Mozilla.-->
                            <!--                            Löschen Sie Ihre Cookies und-->
                            <!--                            deaktivieren Sie die Speicherung für die Zukunft.-->

                            {{ $t('datenschutz.header__subtitle-text') }}
                        </p>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs9>
                <v-layout row wrap class="subheading">
                    <v-flex xs12 class="title teal--text">
                        {{$t('datenschutz.cookie__switch-headers.OPT')}}
                    </v-flex>
                    <v-flex xs12 sm4 md2 lg1 xl1>
                        <v-switch id="switchOpt"
                                  @change="togglePermissions('LI-OPT', controls.LI_OPT)"
                                  v-model="controls.LI_OPT"
                                  class="caption font-weight-light"
                                  color="teal">
                        </v-switch>
                    </v-flex>
                    <v-flex xs12 sm8 md10 lg11 xl11>
                        <p class="mt-2 caption font-weight-light">
                            {{ $t('datenschutz.cookie__switch-texts.OPT') }}
                        </p>
                    </v-flex>
                    <v-expand-transition mode="out-in">
                        <v-flex xs12 v-show="controls.opt">
                            <p class="caption font-weight-light"
                               id="warning-text-opt">
                                {{$t('common.cookie__switch-alert')}}
                            </p>
                            <v-btn @click="setPermissions('LI-OPT')"
                                   class="text-capitalize caption font-weight-light"
                                   color="teal" dark round>
                                {{$t('common.button__yes')}}
                            </v-btn>
                            <v-btn @click="setDefaultPermissions('li_opt')"
                                   class="text-capitalize caption font-weight-light"
                                   color="teal" dark round>
                                {{$t('common.button__no')}}
                            </v-btn>
                        </v-flex>
                    </v-expand-transition>
                    <v-flex xs12>
                        <v-divider class="mb-2"></v-divider>
                    </v-flex>

                    <v-flex xs12 class="title teal--text">
                        {{$t('datenschutz.cookie__switch-headers.OM')}}
                    </v-flex>
                    <v-flex xs12 sm4 md2 lg1>
                        <v-switch id="switchOm"
                                  @change="togglePermissions('LI-OM', controls.LI_OM)"
                                  v-model="controls.LI_OM"
                                  color="teal"
                                  class="font-weight-light caption">
                        </v-switch>
                    </v-flex>
                    <v-flex xs12 sm8 md10 lg11>
                        <p class="caption mt-3 font-weight-light">
                            {{ $t('datenschutz.cookie__switch-texts.OM') }}
                        </p>
                    </v-flex>
                    <v-expand-transition mode="out-in">
                        <v-flex xs12 v-show="controls.om">
                            <p class="caption font-weight-light"
                               id="warning-text-om">
                                {{$t('common.cookie__switch-alert')}}
                            </p>
                            <v-btn @click="setPermissions('LI-OM')"
                                   class="text-capitalize caption font-weight-light"
                                   color="teal" dark round>
                                {{$t('common.button__yes')}}
                            </v-btn>
                            <v-btn @click="setDefaultPermissions('li_om')"
                                   class="text-capitalize caption font-weight-light"
                                   color="teal" dark round>
                                {{$t('common.button__no')}}
                            </v-btn>
                        </v-flex>
                    </v-expand-transition>
                    <v-flex xs12>
                        <v-divider class="mb-2"></v-divider>
                    </v-flex>

                    <template v-if="userData.state === 1">
                        <v-flex xs12 class="title teal--text">
                            {{$t('datenschutz.cookie__switch-headers.NBA')}}
                        </v-flex>
                        <v-flex xs12 sm4 md2 lg1 xl1>
                            <v-switch id="switchNba"
                                      @change="togglePermissions('LI-NBA', controls.LI_NBA)"
                                      v-model="controls.LI_NBA"
                                      class="caption font-weight-light"
                                      color="teal">
                            </v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 md10 lg11 xl11>
                            <p class="mt-2 caption font-weight-light">
                                {{ $t('datenschutz.cookie__switch-texts.NBA')
                                }}
                            </p>
                        </v-flex>
                        <v-expand-transition mode="out-in">
                            <v-flex xs12 v-show="controls.nba">
                                <p class="caption font-weight-light"
                                   id="warning-text-nba">
                                    {{$t('common.cookie__switch-alert')}}
                                </p>
                                <v-btn @click="setPermissions('LI-NBA')"
                                       class="text-capitalize caption font-weight-light"
                                       color="teal" dark round>
                                    {{$t('common.button__yes')}}
                                </v-btn>
                                <v-btn @click="setDefaultPermissions('li_nba')"
                                       class="text-capitalize caption font-weight-light"
                                       color="teal" dark round>
                                    {{$t('common.button__no')}}
                                </v-btn>
                            </v-flex>
                        </v-expand-transition>
                    </template>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  /* eslint-disable */
  // Load functions to use.
  import {loadInfo, updatePermissions} from '../assets/insure';

  export default {
    name: 'Datenschutz',
    /**
     * Object that represent the meta information of site Home page. On page
     * load, above meta tag is added to the page.
     * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="Datenschutz">
     */
    metaInfo: {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Datenschutz',
        },
      ],
    },

    data: () => ({
      // Object to control UI for permissions
      controls: {
        om: false,
        opt: false,
        nba: false,
        LI_OM: true,
        LI_NBA: true,
        LI_OPT: true,
      },

      // Notification object
      notification: {
        bottom: false,
        center: true,
        color: 'info',
        left: false,
        text: '',
        top: true,
        timeout: 3000,
        show: false,
      },

      // Object representing the userData object.
      userData: {
        notificationHistory: [],
        notificationStatus: {
          'LI-OM': false,
          'LI-OPT': false,
          'LI-NBA': false,
        },
        permissions: {
          'LI-OM': true,
          'LI-NBA': true,
          'LI-OPT': true,
        },
        state: 0,
        umdid: '',
        umid: '',
      },
    }),

    methods: {
      /**
       * Toggles a permission and set control of corresponding permission to
       * show a warning text below.
       *
       * @param permission
       * @param value
       */
      togglePermissions(permission, value) {
        switch (permission) {
          case 'LI-OM':
            this.controls.om = this.controls.LI_OM !==
                this.userData.permissions['LI-OM'];
            break;
          case 'LI-OPT':
            this.controls.opt = this.controls.LI_OPT !==
                this.userData.permissions['LI-OPT'];
            break;
          case 'LI-NBA':
            this.controls.nba = this.controls.LI_NBA !==
                this.userData.permissions['LI-NBA'];
            break;
        }
      },

      /**
       * Sends the permission to FC after the "Ja" button is clicked. Successful
       * response notifies the user and hide the warning text.
       *
       * @param permission
       */
      setPermissions(permission) {
        switch (permission) {
          case 'LI-OM':
            this.userData.permissions['LI-OM'] = this.controls.LI_OM;
            updatePermissions({'LI-OM': this.userData.permissions[permission]}).
                // fc.setPermission({'LI-OM': this.userData.permissions[permission]}).
                then(response => {
                  this.notifyEvent(this.$t('common.dialog__success'), 'success');
                  this.userData.permissions['LI-OM'] = response.permissions['LI-OM'];
                  this.controls.LI_OM = response.permissions['LI-OM'];

                  this.setSwitchOff(permission);
                });
            break;
          case 'LI-OPT':
            this.userData.permissions['LI-OPT'] = this.controls.LI_OPT;
            updatePermissions(
                {'LI-OPT': this.userData.permissions[permission]}).
                // fc.setPermission({'LI-OPT': this.userData.permissions[permission]}).
                then(response => {
                  this.notifyEvent(this.$t('common.dialog__success'), 'success');

                  this.userData.permissions['LI-OPT'] = response.permissions['LI-OPT'];
                  this.controls.LI_OPT = response.permissions['LI-OPT'];

                  this.setSwitchOff(permission);
                });
            break;
          case 'LI-NBA':
            this.userData.permissions['LI-NBA'] = this.controls.LI_NBA;
            updatePermissions(
                {'LI-NBA': this.userData.permissions[permission]}).
                // fc.setPermission({'LI-NBA': this.userData.permissions[permission]}).
                then(response => {
                  this.notifyEvent(this.$t('common.dialog__success'), 'success');

                  this.userData.permissions['LI-NBA'] = response.permissions['LI-NBA'];
                  this.controls.LI_NBA = response.permissions['LI-NBA'];

                  this.setSwitchOff(permission);
                });
            break;
        }
      },

      /**
       * Show a notification for an event action.
       */
      notifyEvent(text, type) {
        this.notification.text = text;
        this.notification.color = type;
        this.notification.show = true;
      },

      /**
       * Hides the warning text for permission after it is successfully changed.
       *
       * @param w
       */
      setSwitchOff(w) {
        switch (w) {
          case 'LI-OM':
            this.controls.om = false;
            break;
          case 'LI-OPT':
            this.controls.opt = false;
            break;
          case 'LI-NBA':
            this.controls.nba = false;
            break;
        }
      },

      /**
       * Returns the permission to it´s default state if button "Nein" is
       * clicked when warning text is shown.
       *
       * @param permission
       */
      setDefaultPermissions(permission) {
        switch (permission) {
          case 'li_om':
            this.controls.LI_OM = this.userData.permissions['LI-OM'];
            this.controls.om = false;
            break;
          case 'li_opt':
            this.controls.LI_OPT = this.userData.permissions['LI-OPT'];
            this.controls.opt = false;
            break;
          case 'li_nba':
            this.controls.LI_NBA = this.userData.permissions['LI-NBA'];
            this.controls.nba = false;
            break;
        }
      },
    },

    created() {
      // Go to the top of the page when accessed.
      window.scrollTo(0, 0);

      // Listen for Event. Updates the userData permissions.
      this.$root.$on('update-permissions', userData => {
        this.userData = userData;
        this.controls.LI_OM = userData.permissions['LI-OM'];
        this.controls.LI_OPT = userData.permissions['LI-OPT'];
        this.controls.LI_NBA = userData.permissions['LI-NBA'];
      });
    },

    mounted() {
      // Makes in info call and updates the userData permissions with response
      // data.
      loadInfo().
          then(userData => {
            this.userData = userData;
            this.controls.LI_OM = userData.permissions['LI-OM'];
            this.controls.LI_OPT = userData.permissions['LI-OPT'];
            this.controls.LI_NBA = userData.permissions['LI-NBA'];
          });
    },
  };
</script>
