<template>
  <v-app>
    <v-snackbar color="grey darken-4"
                :timeout="0"
                :value="cookiesNotification"
                :multi-line="$vuetify.breakpoint.xsOnly"
                :top="$vuetify.breakpoint.smAndUp"
                :bottom="$vuetify.breakpoint.xsOnly">
      <div class="font-weight-light">
        {{ $t("common.dialog__cookie-acknowledgment") }}
        <router-link class="font-weight-light text-capitalize orange--text"
                     to="/datenschutz"
                     flat dark>
          {{ $t("common.dialog__cookie-link-privacy") }}
        </router-link>
      </div>
      <v-btn class="ml-5"
             color="orange"
             @click.prevent="$store.dispatch('acceptVisitorCookies')"
             :loading="processing"
             dark small>
        {{ $t("common.dialog__cookie-button") }}
      </v-btn>
    </v-snackbar>
    <v-navigation-drawer v-model="drawer" absolute temporary app>
      <v-list dense>
        <v-list-tile
            v-for="item in navItems"
            :key="item.name"
            :to="item.path"
            active-class="teal--text">
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t(`toolbar__main-menu.${item.name.toLocaleLowerCase()}`) }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
            @click="$store.dispatch('toggleForm', {name: 'register', value: true})">
          <v-list-tile-action></v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Register</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar dense flat>
      <v-spacer class="hidden-md-and-down"></v-spacer>
      <v-toolbar-title class="font-weight-light caption ml-4">
        {{ $t("toolbar__top-title") }}
      </v-toolbar-title>
      <v-spacer class="hidden-sm-and-up"></v-spacer>
      <v-spacer class="mx-5 hidden-xs-only"></v-spacer>
      <v-spacer class="mx-5 hidden-xs-only"></v-spacer>
      <span class="font-weight-light caption">Email:&nbsp;</span>
      <a class="menu font-weight-light caption teal--text"
         href="mailto:support@teavaro.com">support@teavaro.com</a>
      <v-menu class="ml-2">
        <v-btn slot="activator"
               color="teal"
               flat round>
          <v-icon class="mx-1 grey--text text--darken-2"
                  small>translate
          </v-icon>
          <sup>{{ currentLanguage }}</sup>
        </v-btn>
        <v-list v-model="$i18n.locale"
                v-for="(lang, i) in languages"
                :key="`Lang${i}`"
                dense>
          <v-list-tile @click="changeLocale(lang.id)"
                       class="mx-0 px-0">
            <v-list-tile-action class="text-capitalize font-weight-light">
              {{ lang.name }}
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-spacer class="hidden-md-and-down"></v-spacer>
      <v-spacer class="hidden-md-and-down"></v-spacer>
    </v-toolbar>
    <v-divider class="grey--text"></v-divider>
    <v-toolbar clipped-left flat mt-1>
      <v-spacer class="hidden-md-and-down"></v-spacer>
      <v-toolbar-side-icon v-if="$vuetify.breakpoint.smAndDown"
                           @click.stop="drawer = !drawer"
                           class="ml-3">
        <v-icon color="teal">menu</v-icon>
      </v-toolbar-side-icon>
      <v-toolbar-title>
        <router-link
            class="display-1 mr-4 teal--text lighten-5 font-weight-black menu"
            to="/">
          InsureMe
        </router-link>
      </v-toolbar-title>
      <v-spacer class="mx-4 hidden-md-and-down"></v-spacer>
      <v-spacer class="hidden-lg-and-up"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down ml-5">
        <v-btn v-for="(link,i) in navItems" :key="i" :to="link.path"
               :class="link.classes"
               active-class="teal--text"
               :ripple="false"
               round small
               :flat="link.attr">
          {{ $t(`toolbar__main-menu.${link.name.toLocaleLowerCase()}`) }}
        </v-btn>
      </v-toolbar-items>
      <v-btn v-show="!userLoggedIn"
             @click="$store.dispatch('setDialogStatus', {name: 'login', value: true})"
             color="teal"
             class="font-weight-light text-capitalize caption"
             round dark>
        {{ $t("common.button__login") }}
      </v-btn>
      <v-menu class="font-weight-light"
              offset-y dense v-show="userLoggedIn">
        <v-btn class="text-none"
               slot="activator"
               color="teal"
               flat icon>
          <v-tooltip color="teal"
                     lazy top>
            <template v-slot:activator="{on, attrs}">
              <v-icon v-bind="attrs"
                      v-on="on">account_circle
              </v-icon>
            </template>
            <span>{{ userEmail }}</span>
          </v-tooltip>
        </v-btn>
        <v-list dense>
          <v-list-tile
              @click="$store.dispatch('setDialogStatus', {name: 'password_change', value: true})">
            <v-list-tile-content>
              <v-list-tile-title>
                {{ $t("common.button__password-change") }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon small>vpn_key</v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile @click="$store.dispatch('logOut')">
            <v-list-tile-content>
              <v-list-tile-title>
                {{ $t("common.button__logout") }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon small>logout</v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile @click="removeAccount">
            <v-list-tile-content>
              <v-list-tile-title class="error--text">
                {{ $t("common.button__remove-account") }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon color="error" small>delete_forever</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-btn v-if="!userLoggedIn"
             @click="$store.dispatch('toggleForm', {name: 'register', value: true})"
             class="font-weight-light text-capitalize hidden-xs-only caption"
             color="grey lighten-3"
             round text depressed>
        {{ $t("common.button__register") }}
      </v-btn>
      <v-spacer class="mx-1 hidden-md-and-down"></v-spacer>
    </v-toolbar>
    <v-content>
      <login-dialog :load="loginDialog"
                    @toggle-login-form="toggleLoginDialog"></login-dialog>
      <register-dialog :load="registerDialog"
                       @toggle-register-form="toggleRegisterDialog"></register-dialog>
      <password-recovery :load="passwordRecoverDialog"
                         @toggle-recovery-form="toggleRecoveryDialog"></password-recovery>
      <password-change :load="passwordChangeDialog"
                       @toggle-password-change-form="togglePasswordChangeDialog"></password-change>
      <customer-notification :load="customerNotification"
                             :user_email="user.email"
                             @toggle-customer-notification="toggleCustomerDialog"></customer-notification>
      <event-notification @toggle-notification="toggleNotificationEvent"
                          :display="notification.show"
                          :text="notification.text"
                          :type="notification.type"
                          @toogle-notification="$store.dispatch('toggleNotification')"></event-notification>
      <v-card :max-height="$route.path!=='/' ? 210 : 380"
              color="teal"
              dark flat>
        <v-img :src="require('@/assets/partner.jpg')"
               :max-height="$route.path!=='/' ? 210 : 380"
               gradient="to top right, rgba(25,32,72,.7), rgba(9,61,72,0.7)"
               transition="slide-transition"
               mode="out-in"
               cover>
          <v-container align-center fill-height
                       :justify-center="$vuetify.breakpoint.mdAndDown">
            <v-container>
              <v-layout row align-center
                        :justify-center="$vuetify.breakpoint.mdAndDown">
                <v-flex xs12 sm8 md7 lg4
                        :class="$vuetify.breakpoint.lgAndUp ? 'ml-2' : 'text-xs-center'">
                  <h1 class="display-1 font-weight-regular">
                    {{ $t(headerText.title) }}
                  </h1>
                  <p class="caption font-weight-light">
                    {{ $t(headerText.subtitle) }}
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-img>
      </v-card>
      <v-container fluid>
        <div id="ads_banner"></div>
        <router-view></router-view>
      </v-container>
    </v-content>
    <v-footer height="auto">
      <v-container fluid>
        <v-container fluid grid-list-md>
          <v-layout row wrap justify-center>
            <v-flex xs9>
              <v-layout row wrap>
                <v-flex xs12 sm6 md4>
                  <router-link to="/"
                               class="headline teal--text font-weight-medium menu">
                    InsureMe
                  </router-link>
                  <p class="caption my-1" style="line-height: 2em">
                    {{ $t("home.footer.promotion-text") }}
                  </p>
                  <h6 class="caption my-5 font-weight-regular">
                    {{ $t("home.footer.copyright") }}.<br>
                    <code>v{{ version }}</code>
                  </h6>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-layout row wrap justify-space-between>
                    <v-flex xs12 sm12 md5>
                      <h4 class="mt-2">Quick View</h4>
                      <router-link
                          class="menu font-weight-light caption my-2"
                          v-for="(link,i) in navItems"
                          :key="i"
                          :to="link.path">
                        {{
                          $t(`toolbar__main-menu.${link.name.toLocaleLowerCase()}`)
                        }}
                      </router-link>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                      <h4 class="mt-2">
                        {{ $t("produkte") }}</h4>
                      <router-link
                          class="menu font-weight-light caption my-2"
                          v-for="(link,i) in products"
                          :key="i"
                          :to="link.path">
                        {{ $t(`home.footer.products.${link.name}`) }}
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <h4 class="mt-2">{{ $t("kontakt.title") }}</h4>
                  <a class="menu font-weight-light caption my-2"
                     href="mailto:support@teavaro.com">Email:
                    support@teavaro.com
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// Load helpers and functions.
import {internalTrack, loadCampaign, loadInfo} from "./assets/insure";
import {getConsoleInfoFunc} from "@/helpers";
import {mapState} from "vuex";

export default {
  name: "App",
  /**
   * Object that represent the meta information of site Home page. On page
   * load, above meta tag is added to the page.
   * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="Home">
   */
  metaInfo: {
    meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "Home",
      },
    ],
  },
  /**
   * Register components imported before into the site so they can be used
   * normally.
   */
  components: {
    LoginDialog: () => import('@/components/LoginDialog'),
    RegisterDialog: () => import('@/components/RegisterDialog'),
    CustomerNotification: () => import('@/components/CustomerNotification'),
    EventNotification: () => import('@/components/EventNotification'),
    PasswordRecovery: () => import('@/components/PasswordRecovery'),
    PasswordChange: () => import('@/components/PasswordChange'),
  },
  /**
   * Watch for variable changes and fire actions in response.
   */
  watch: {
    /**
     * When page route changes, this actions are fired:
     * Reload Celtra banner.
     * Track current page.
     * @param to
     * @param from
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.$nextTick(() => {
        // Update meta information with current page.
        this.$meta().refresh();
        // Track current route
        if (this.userData.permissions["LI-OM"]) internalTrack();
        // Reload banner to update any change related to user behaviour in site.
        let banner = document.getElementById("ads_banner");
        if (banner.firstChild !== null) {
          banner.firstChild.remove();
          this.loadBestActionBanner();
        }
      });
    },
    /**
     * When any permission changes, this actions are fired:
     * Reload Celtra banner.
     * @param old
     * @param current
     */
    // eslint-disable-next-line no-unused-vars
    "userData.permissions"(old, current) {
      let banner = document.getElementById("ads_banner");
      if (banner.firstChild !== null) {
        banner.firstChild.remove();
        this.loadBestActionBanner();
      }
    },
  },
  computed: {
    ...mapState({
      customerNotification: state => state.notify.customer,
      permissions: state => state.user.fcData.permissions,
      userEmail: state => state.user.email,
      userLoggedIn: state => state.user.logged_in,
      loginDialog: state => state.dialogs.login,
      registerDialog: state => state.dialogs.register,
      passwordRecoverDialog: state => state.dialogs.password_recover,
      passwordChangeDialog: state => state.dialogs.password_change,
      cookiesNotification: state => state.dialogs.cookies_opt_in,
      notification: state => state.notification
    }),
    processing: {
      get() {
        return this.$store.state.processing;
      },
      set(value) {
        return this.$store.commit('SET_PROCESSING_STATUS', {value});
      },
    },
    /**
     * Returns the pair [{tile}, {subtitle}] match of text for current route.
     *
     * @returns {VueI18n.TranslateResult}
     */
    headerText() {
      return this.$t(this.$route.name)
    },
    // Return full name of current selected language.
    currentLanguage() {
      let language = this.languages.filter(lang => {
        return lang.id === this.$i18n.locale;
      });
      return language[0].name;
    },
  },
  data: () => ({
    // Handle the login component visibility.
    login: false,
    // Handle the left side navigation visibility.
    drawer: false,
    // List of site product routes with classes attributes.
    products: [
      {
        name: "Hausratversicherung",
        path: "/hausrat",
        classes: "caption font-weight-light text-capitalize small",
        attr: "flat",
      },
      {
        name: "Haftpflichtversicherung Single",
        path: "/haftpflicht/basic",
        classes: "caption font-weight-light text-capitalize small",
        attr: "flat",
      },
      {
        name: "Haftpflichtversicherung Family",
        path: "/haftpflicht/family",
        classes: "caption font-weight-light text-capitalize small",
        attr: "flat",
      },
    ],
    // List of routes for left navigation in small devices.
    navItems: [
      {
        name: "home",
        path: "/",
        classes: "caption font-weight-light text-capitalize mx-0",
        attr: "flat",
      },
      {
        name: "Haftpflicht",
        path: "/haftpflicht",
        classes: "caption font-weight-light text-capitalize mx-0",
        attr: "flat",
      },
      {
        name: "Hausrat",
        path: "/hausrat",
        classes: "caption font-weight-light text-capitalize mx-0",
        attr: "flat",
      },
      {
        name: "Privacy-policy",
        path: "/datenschutz",
        classes: "caption font-weight-light text-capitalize mx-0",
        attr: "flat",
      },
      {
        name: "Contact",
        path: "/kontakt",
        classes: "caption font-weight-light text-capitalize small",
        attr: "flat",
      },
    ],
    // Handle the registration component visibility.
    register: false,
    // Handle the display of the visitor cookie notification.
    visitorNotification: false,
    // Represent the userData information.
    userData: {
      notificationHistory: [],
      notificationStatus: {
        "LI-OM": false,
        "LI-OPT": false,
        "LI-NBA": false,
      },
      permissions: {
        "LI-OM": true,
        "LI-NBA": true,
        "LI-OPT": true,
      },
      state: 0,
      umdid: '',
      umid: '',
    },
    // Represent the user information to login.
    user: {
      id: null,
      name: '',
      email: '',
      logged_in: false,
    },
    // Handle the password recovery component visibility.
    recovery: false,
    // List of available languages.
    languages: [{id: "de", name: "De"}, {id: "en", name: "En"}],
    // Handle the password change form visibility.
    passwordChange: false,
    version: process.env.VUE_APP_VERSION,
  }),
  methods: {
    /**
     * Closes the registration Popup.
     */
    toggleRegisterDialog() {
      this.register = !this.register;
    },
    /**
     * Closes the Login Popup.
     */
    toggleLoginDialog() {
      this.login = false;
    },
    /**
     * Closes the Customer notification Popup.
     */
    toggleCustomerDialog() {
      this.customerNotification = !this.customerNotification;
    },
    /**
     * Toggles the visibility of a notification popup
     */
    toggleNotificationEvent() {
      this.notification.show = false;
    },
    /**
     * Get the campaing creative from Celtra and shows in the site.
     */
    loadBestActionBanner() {
      let banner_code = loadCampaign("f4e29141", this.$i18n.locale,
          this.userData.permissions["LI-OM"]);
      let banner = document.getElementById("ads_banner");
      banner.insertAdjacentElement("afterbegin", banner_code);
    },
    /**
     * Closes the password recovery popup.
     */
    toggleRecoveryDialog() {
      this.recovery = !this.recovery;
    },
    /**
     * Switch language for site
     */
    changeLocale(locale) {
      this.$i18n.locale = locale;
      sessionStorage.setItem(window.location.hostname + "__language", locale);
      let banner = document.getElementById("ads_banner");
      if (banner.firstChild !== null) {
        banner.firstChild.remove();
        this.loadBestActionBanner();
      }
    },
    /**
     * Closes the password change pop up.
     */
    togglePasswordChangeDialog() {
      this.passwordChange = false;
    },
    /**
     * Log out the current user and send request to delete account.
     */
    removeAccount() {
      if (confirm(this.$t('common.alert__confirm-remove-account')))
        this.$store.dispatch('removeUserAccount')
    },
  },
  created() {
    // Set the "lang" attribute on html global tag.
    document.documentElement.setAttribute("lang", this.$i18n.locale);
    // Listen for Event. Updates the user logged data.
    this.$root.$on("set-logged-user", data => {
      this.user.id = data.id;
      this.user.name = data.name;
      this.user.email = data.email;
      this.user.logged_in = true;
    });
    // Listen for Event. Displays a notification popup with data received.
    this.$root.$on("event-notification", data => {
      this.notification.color = data.status;
      this.notification.text = data.text;
      this.notification.show = true;
    });
    // Listen for Event. Make an info call and reload the Celtra banner in the site.
    this.$root.$on("load-best-action-banner", () => {
      loadInfo().then(() => this.loadBestActionBanner());
    });
    // Listen for Event. Sets a success subtitle text on Email confirmation page
    this.$root.$on("email_confirmed", () => {
      this.headerText.subtitle = this.$t("common.header__subtitle-activation-successful");
    });
    // Listen for Event. Sets an error subtitle text on Email confirmation page
    this.$root.$on("email_unknown", () => {
      this.headerText.subtitle = this.$t("common.header__subtitle-activation-error");
    });
    // Toggles the recovery form.
    this.$root.$on("toggle-recovery-form", () => {
      this.recovery = true;
    });
  },
  mounted() {
    // Fired initially on site mounted.
    const currentLanguage = sessionStorage.getItem(`${window.location.hostname}__language`);
    currentLanguage !== null ?
        this.$i18n.locale = currentLanguage :
        sessionStorage.setItem(`${window.location.hostname}__language`, this.$i18n.locale)
    getConsoleInfoFunc("info", `Display lang "${currentLanguage}"`)

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("debug_mode") === "true" &&
        window.sessionStorage.getItem("SET_DB_MODE") === null) {
      window.sessionStorage.setItem("SET_DB_MODE", true)
    } else if (urlParams.get("debug_mode") === "false" &&
        window.sessionStorage.getItem("SET_DB_MODE") !== null) {
      window.sessionStorage.removeItem("SET_DB_MODE")
    }
    this.$store.dispatch("start")
  },
};
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.menu {
  display: block;
  list-style: none;
  text-decoration: none;
  color: black;
}

.menu:hover {
  color: #38ADA9;
}

.short-divider {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 30%;
}

.circle {
  padding: 35px;
  background-color: #38ADA9;
  border-radius: 50%;
}

.wider-line-height {
  line-height: 2em;
}
</style>
