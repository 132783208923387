<template>
    <v-container grid-list-md>
        <v-layout row wrap justify-center>
            <v-flex xs9>
                <v-card color="transparent" flat>
                    <v-card-title
                            class="display-1 text-xs-center font-weight-medium teal--text mb-4">
                        <v-spacer></v-spacer>
                        Tarife Haftpflicht
                        <v-spacer></v-spacer>
                    </v-card-title>

                    <v-layout row wrap justify-center>
                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" flat hover to="/haftpflicht/basic">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
                                        <!--                                        Single Basic-->
                                        {{$t('haftpflicht.tariff_single.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>
                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 5,30 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
                                        <!--                                        pro Monat-->
                                        {{$t('haftpflicht.tariff_single.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
                                        <!--                                        Deckungssumme bis <br>-->
                                        <!--                                        6 Millionen <br>-->
                                        <!--                                        Eigenbeteiligung: <br>-->
                                        <!--                                        5% derSchadenssumme-->
                                        {{$t('haftpflicht.tariff_single.text-1')}}<br>
                                        {{$t('haftpflicht.tariff_single.text-2')}}<br>
                                        {{$t('haftpflicht.tariff_single.text-3')}}<br>
                                        {{$t('haftpflicht.tariff_single.text-4')}}
                                    </p>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize mb-0" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>

                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" raised style="border: 3px solid #38ADA9; border-bottom: none"
                                    class="elevation-10">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
                                        <!--                                        Single Premium-->
                                        {{$t('haftpflicht.tariff_premium.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>

                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 55,70 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
                                        <!--                                        pro Monat-->
                                        {{$t('haftpflicht.tariff_premium.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
                                        <!--                                        Deckungssumme bis <br>-->
                                        <!--                                        60 Millionen Euro <br>-->
                                        <!--                                        Ohne Selbstbeteiligung <br>-->
                                        <!--                                        Inklusive Vermieterschutz <br>-->
                                        <!--                                        Inklusive Diensthaftpflicht-->
                                        {{$t('haftpflicht.tariff_premium.text-1')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-2')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-3')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-4')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-5')}}
                                    </p>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize elevation-10" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>

                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" flat hover to="/haftpflicht/family">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
                                        <!--                                        Family-->
                                        {{$t('haftpflicht.tariff_family.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>
                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 97,44 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
                                        <!--                                        pro Monat-->
                                        {{$t('haftpflicht.tariff_premium.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
                                        <!--                                        Deckungssumme bis <br>-->
                                        <!--                                        30 Millionen Euro <br>-->
                                        <!--                                        inkl. Absicherung des Ehepartners <br>-->
                                        <!--                                        + aller im Haushalt lebenden Kinder-->
                                        {{$t('haftpflicht.tariff_premium.text-1')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-2')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-3')}}<br>
                                        {{$t('haftpflicht.tariff_premium.text-4')}}
                                    </p>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>

                        <v-flex xs12 sm12 md7 lg3>
                            <v-card height="335" flat hover to="/haftpflicht/kombi">
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-icon color="teal" medium>crop_portrait
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <div class="title text-xs-center teal--text font-weight-medium">
                                        <!--                                        Kombi-->
                                        {{$t('haftpflicht.tariff_combo.header')}}
                                    </div>
                                    <v-layout row justify-center>
                                        <v-divider
                                                class="text-xs-center short-divider"></v-divider>
                                    </v-layout>
                                    <div class="grey--text text--darken-1 text-xs-center display-1">
                                        ab 6,50 €
                                    </div>
                                    <div class="text-xs-center grey--text caption">
                                        <!--                                        pro Monat-->
                                        {{$t('haftpflicht.tariff_combo.monat')}}
                                    </div>
                                    <p class="grey--text text-xs-center wider-line-height font-weight-light caption mt-2">
                                        <!--                                        Deckungssumme bis <br>-->
                                        <!--                                        6 Millionen Euro <br>-->
                                        <!--                                        Ideal für Singles <br>-->
                                        <!--                                        mit Wohnraum bis 75 m² <br>-->
                                        <!--                                        Im Kombipaket günstiger-->
                                        {{$t('haftpflicht.tariff_combo.text-1')}}<br>
                                        {{$t('haftpflicht.tariff_combo.text-2')}}<br>
                                        {{$t('haftpflicht.tariff_combo.text-3')}}<br>
                                        {{$t('haftpflicht.tariff_combo.text-4')}}<br>
                                        {{$t('haftpflicht.tariff_combo.text-5')}}<br>
                                    </p>
                                </v-card-text>
                                <v-card-text>
                                </v-card-text>
                            </v-card>
                            <v-btn class="mt-0 text-capitalize" color="teal"
                                   depressed dark block>
                                {{$t('common.button__get-offer')}}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'HaftpflichtVersicherungPremium',
    /**
     * Object that represent the meta information of site Home page. On page
     * load, above meta tag is added to the page.
     * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="HaftpflichtPremium">
     */
    metaInfo: {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'HaftpflichtPremium',
        },
      ],
    },

    created() {
      // Moves to top of the page when load.
      window.scrollTo(0,0);
    }
  };
</script>

<style scoped>

</style>