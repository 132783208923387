import Vue from 'vue';
import Router from 'vue-router';
import router from './router';
import Meta from 'vue-meta';
import './plugins/vuetify';
import './plugins/axios';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@/assets/insure.js';
import App from './App.vue';
import i18n from './i18n';
import store from './store'

Vue.use(Router);

/* Configuration for the Meta Information so it can be used in any page */
Vue.use(Meta, {
    refreshOnceOnNavigation: true,
    tagIDKeyName: 'vmid',
    keyName: 'metaInfo', /* Key name must match with object  used in pages*/
});
Vue.config.productionTip = false;

/* eslint-disable */
new Vue({
    router: router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');
