import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    /* Routes available for the site */
    routes: [
        {
            name: 'home',
            path: '/',
            component: require('@/components/HomePage').default,
        },
        {
            name: 'haftpflicht',
            path: '/haftpflicht',
            component: require('@/views/Haftpflicht.vue').default,
        },
        {
            name: 'haftpflicht_versicherung_basic',
            path: '/haftpflicht/basic',
            component: require('@/views/HaftpflichtVersicherungBasic.vue').default,
        },
        {
            name: 'haftpflicht_versicherung_premium',
            path: '/haftpflicht/premium',
            component: require('@/views/HaftpflichtVersicherungPremium.vue').default,
        },
        {
            name: 'haftpflicht_versicherung_family',
            path: '/haftpflicht/family',
            component: require('@/views/HaftpflichtVersicherungFamily.vue').default,
        },
        {
            name: 'haftpflicht_versicherung_kombi',
            path: '/haftpflicht/kombi',
            component: require('@/views/HaftpflichtVersicherungKombi.vue').default,
        },
        {
            name: 'hausrat',
            path: '/hausrat',
            component: require('@/views/Hausrat.vue').default,
        },
        {
            name: 'hausrat_standard_1',
            path: '/hausrat/standard/1',
            component: require('@/views/HausratKombi.vue').default,
        },
        {
            name: 'hausrat_standard_2',
            path: '/hausrat/standard/2',
            component: require('@/views/HausratBasis.vue').default,
        },
        {
            name: 'kontakt',
            path: '/kontakt',
            component: require('@/views/Kontakt.vue').default,
        },
        {
            name: 'datenschutz',
            path: '/datenschutz',
            component: require('@/views/Datenschutz.vue').default,
        },
        {
            name: 'email_confirmation',
            path: '/email_confirmation/:email',
            component: require('@/views/EmailConfirmation.vue').default,
        },
        {
            name: 'password_reset',
            path: '/password_reset',
            component: require('@/views/PasswordReset.vue').default,
        },
        {
            name: 'content_not_found',
            path: '/content_not_found',
            component: require('@/views/ContentNotFound').default,
        },
    ],
});
