<template>
    <v-container grid-list-md>
        <v-layout row wrap justify-center>
            <v-flex xs9>
                <v-card color="transparent" flat>
                    <v-layout row wrap>
                        <v-flex xs12 sm10 md7 lg4>
                            <v-text-field :label="$t('kontakt.form.field__name-header')"
                                          outline required
                                          clearable></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm10 md7 lg4>
                            <v-text-field :label="$t('kontakt.form.field__email-header')"
                                          outline required
                                          clearable></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm10 md7 lg4>
                            <v-text-field :label="$t('kontakt.form.field__title-header')"
                                          outline required
                                          clearable></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-textarea outline
                                        :label="$t('kontakt.form.field__description-header')"
                                        rows="10"
                                        required clearable></v-textarea>
                        </v-flex>
                    </v-layout>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="font-weight-light text-capitalize" color="teal" dark round right small depressed>
                            {{$t('common.button__send')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'kontakt',
    /**
     * Object that represent the meta information of site Home page. On page
     * load, above meta tag is added to the page.
     * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="Kontakt">
     */
    metaInfo: {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Kontakt',
        },
      ],
    },

    created() {
      // Moves to top of the page when load.
      window.scrollTo(0,0);
    }
  };
</script>

<style scoped>

</style>