<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap justify-center>
      <v-flex xs9>
        <v-card color="transparent" flat>
          <v-card-title
              class="display-1 text-xs-center font-weight-medium teal--text mb-4">
            <v-spacer></v-spacer>
            {{ $t("home.body.header__main") }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md4>
              <v-card color="transparent" flat>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div class="circle">
                    <v-icon large dark>crop_portrait</v-icon>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="subheading teal--text text-xs-center">
                  {{ $t("home.body.subtitle-one") }}
                </v-card-text>
                <v-card-text
                    class="text-xs-center wider-line-height font-weight-light caption">
                  {{ $t("home.body.subtitle-one-text") }}
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <v-card color="transparent" flat>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div class="circle">
                    <v-icon large dark>crop_portrait
                    </v-icon>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="subheading teal--text text-xs-center">
                  {{ $t("home.body.subtitle-two") }}
                </v-card-text>
                <v-card-text
                    class="text-xs-center wider-line-height font-weight-light caption">
                  {{ $t("home.body.subtitle-two-text") }}
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <v-card color="transparent" flat>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <div class="circle">
                    <v-icon large dark>crop_portrait
                    </v-icon>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="subheading teal--text text-xs-center">
                  {{ $t("home.body.subtitle-three") }}
                </v-card-text>
                <v-card-text
                    class="text-xs-center wider-line-height font-weight-light caption">
                  {{ $t("home.body.subtitle-three-text") }}
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-card-title
              class="display-1 text-xs-center font-weight-medium teal--text mt-5 mb-2">
            <v-spacer></v-spacer>
            {{ $t("home.body.header__products") }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md4>
              <v-card color="transparent" to="/hausrat" flat>
                <v-img :src="require('@/assets/hausrat.jpg')" cover></v-img>
                <router-link to="/hausrat"
                             class="subheading teal--text text-xs-left my-1 menu">
                  {{ $t("home.body.link__product-one") }}
                </router-link>

                <p class="text-xs-left caption font-weight-light wider-line-height">
                  {{ $t("home.body.link__product-one-text") }}
                </p>
              </v-card>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <v-card color="transparent" to="/haftpflicht/basic" flat>
                <v-img :src="require('@/assets/haftpflicht_single.jpg')"
                       cover></v-img>
                <router-link to="/haftpflicht/basic"
                             class="subheading teal--text text-xs-left my-1 menu">
                  {{ $t("home.body.link__product-two") }}
                </router-link>
                <p class="text-xs-left caption font-weight-light wider-line-height">
                  {{ $t("home.body.link__product-two-text") }}
                </p>
              </v-card>
            </v-flex>

            <v-flex xs12 sm12 md4>
              <v-card color="transparent" to="/haftpflicht/family" flat>
                <v-img :src="require('@/assets/familienhaftpflicht.jpg')"
                       cover></v-img>
                <router-link to="/haftpflicht/family"
                             class="subheading teal--text text-xs-left my-1 menu">
                  {{ $t("home.body.link__product-three") }}
                </router-link>
                <p class="text-xs-left caption font-weight-light wider-line-height">
                  {{ $t("home.body.link__product-three-text") }}
                </p>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import {getConsoleInfoFunc} from "@/helpers";

export default {
  name: "HelloWorld",
  data: () => ({
    image: "",
  }),
  created() {
    window.scrollTo(0, 0);
    this.image = require("../assets/partner.jpg");
    getConsoleInfoFunc("info", "Home created event")
  },
  mounted() {
    getConsoleInfoFunc("info", "Home mounted event");
  },
};
</script>

<style scoped>
.circle {
  padding: 35px;
  background-color: #38ADA9;
  border-radius: 50%;
}

.wider-line-height {
  line-height: 2em;
}
</style>
