<template>
    <v-container grid-list-md fill-height>
        <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm11 md9>
                <v-alert :value="true"
                         :type="alert.type"
                >
                    {{$t('common.alert__content-no-found')}}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'ContentNotFound',
    /**
     * Object that represent the meta information of site ContentNotFound page. On page
     * load, above meta tag is added to the page.
     * <meta data-vue-meta="1" vmid="og:title" property="og:title" content="ContentNotFound">
     */
    metaInfo: {
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'ContentNotFound',
        },
      ],
    },

    data: () => ({
      // Object representing an alert into the recovery form.
      alert: {
        display: false,
        type: 'error',
        text: '',
      },
    }),

    methods: {},

    created() {
    },

    mounted() {
    },
  };
</script>

<style scoped>

</style>