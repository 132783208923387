/* eslint-disable no-console */
export const getConsoleInfoFunc = (type, ...msg) => {
    const IS_DEBUG_MODE = JSON.parse(sessionStorage.getItem("SET_DB_MODE"))
    if (IS_DEBUG_MODE !== null && IS_DEBUG_MODE === true) {
        switch (type) {
            case "error":
                console.error(...msg)
                break
            case "info":
                console.info(...msg)
                break
            default:
                console.log(...msg)
                break
        }
    }
}
